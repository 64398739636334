import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  isLoaded: false,
  pilotes: []
};

const slice = createSlice({
  name: 'pilotes stats',
  initialState,
  reducers: {
    getPilotes(state, action) {
      const { pilotes } = action.payload;
      state.pilotes = pilotes;
      state.isLoaded = true;
    }
  }
});

export const reducer = slice.reducer;

export const getPilotes = () => async dispatch => {
  const response = await axios.get('/api/pilotes');
  dispatch(slice.actions.getPilotes(response.data));
};
