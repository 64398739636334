import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'GardianWebSSO',
  logout: () => { },
  redirectAuth: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  const redirectAuth = () => {
    const searchParams = new URLSearchParams();
    if (window.location.pathname && window.location.pathname !== '/')
      searchParams.append('resource', window.location.pathname);
    var url = new URL(window.location.origin + '/api/auth/login');
    url.search = searchParams.toString();

    window.location.assign(url.toString());
    return null;
  };

  useEffect(() => {
    const initialise = async () => {
      try {

        const response = await fetch('/api/account/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.status === 200) {
          const user = await response.json();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'GardianWebSSO',
        logout,
        redirectAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
