/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const rootItem = {
  title: 'Accueil',
  icon: HomeOutlinedIcon,
  href: '/',
}

const sections = [
  {
    subheader: 'Bornes',
    items: [
      {
        title: 'Liste des bornes',
        icon: ListAltOutlinedIcon,
        href: '/terminals/',
      },
      // {
      //   title: 'Créer une borne',
      //   icon: CreateNewFolderOutlinedIcon,
      //   href: '/terminal/create',
      // }

    ]
  },
  {
    subheader: 'Modèle de traitement',
    items: [
      {
        title: 'Liste des images',
        icon: ListAltOutlinedIcon,
        href: '/classification/',
      },
      {
        title: 'Classifier des images',
        icon: CreateNewFolderOutlinedIcon,
        href: '/classification/process/',
      },
      {
        title: 'Liste des modèles',
        icon: CreateNewFolderOutlinedIcon,
        href: '/classification/models',
      }

    ]
  },
  {
    subheader: 'Publicités',
    items: [
      {
        title: 'Liste des publicités',
        icon: ListAltOutlinedIcon,
        href: '/advertising/',
      },
      {
        title: 'Ajouter une publicité',
        icon: CreateNewFolderOutlinedIcon,
        href: '/advertising/create',
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function getProfile(user) {

  console.log(user);
  return "User"
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/account">
              <Avatar
                // alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {'Votre statut : ' + getProfile(user)}
              <Typography
                component={'span'}
                variant="body2"
                color="primary"
                display='inline'
              >
                {user.tier}
              </Typography>

            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <NavItem
            depth={0}
            href={rootItem.href}
            icon={rootItem.icon}
            info={rootItem.info}
            key={"Accueil"}
            title={rootItem.title}
          />
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
