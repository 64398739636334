import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      // src="/static/logo.svg"
      width="42px"
      height="42px"
      src="/static/icons/icon192x192.png"
      {...props}
    />
  );
}

export default Logo;
